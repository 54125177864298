import React, { useEffect, useState, useRef } from 'react';
import Axios from '../../config/axios';
import { Drawer, Card, message } from 'antd';
import { getGroupedReports, downloadReportByName } from '../../services/ReportsService';
import { Fragment } from 'react';
import { toast, confirm } from '@rickylandino/react-messages';
import SelectShowModal from '../Helpers/SelectShowModal';

function ReportsSlider(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const listRef = useRef();

    const [state, setState] = useState({
        showReportCriteriaModal: false,
        showSelectShowModal: false,
        selectedReport: null
    });

    const [modalInfo, setModalInfo] = useState({
        show: false
    });

    const [height, setHeight] = useState({
        triggered: false,
        value: ''
    });

    const [reportsToDisplay, setReportsToDisplay] = useState({});
    const [biggestGroup, setBiggestGroup] = useState(null);
    const [selectedReport, setSelectedReport] = useState({});

    useEffect(() => {
        getGroupedReports(userInfo.userId, userInfo.jobShopDivision).then(data => {
            if (data) {
                setReportsToDisplay(data);

                if (Object.keys(data).length == 0) {
                    setHeight({
                        ...height,
                        triggered: true
                    });
                }

                var size = 0;
                var bg = null;
                for (const property in data) {
                    if (data[property].length > size) {
                        size = data[property].length;
                        bg = property;
                    }
                }

                setBiggestGroup(bg);
            } else {
                toast.error("Something went wrong");
            }
        });
    }, []);

    useEffect(() => {

        setTimeout(() => getListSize(), 500);

        window.addEventListener("resize", getListSize);
    }, [listRef]);

    useEffect(() => {
        if (props.showModal) {
            setModalInfo({
                show: props.showModal
            });
        }
    }, [props.showModal]);

    const getListSize = () => {
        const newHeight = listRef?.current?.clientHeight;
        if (newHeight) {
            setHeight({
                triggered: true,
                value: newHeight
            });
        }
    };

    function handleClose() {
        setModalInfo({
            show: false
        });

        setState({
            ...state,
            showReportCriteriaModal: false
        });

        setTimeout(() => { props.hideModal(); }, 1000);
    }

    function hideSelectShowModal() {
        setTimeout(() => {
            setState({
                ...state,
                showSelectShowModal: false,
                showReportCriteriaModal: true
            });
        }, 1000);
    }

    function handleReportRequest(report) {
        setSelectedReport(report);

        setState({
            ...state,
            showSelectShowModal: true,
        });
    }

    //function handleReportRequest(e, report) {
    //    console.log(report);

    //    e.preventDefault();

    //    setState({
    //        ...state,
    //        selectedReport: e.target.id,
    //        selectedReportId: e.target.value,
    //        showSelectShowModal: true,
    //    });
    //}

    function downloadReport(reportName, reportOption, selectedShow) {
        console.log(reportName);
        console.log(reportOption);
        console.log(selectedShow);

        //toast.loading("Report generation in progress...", { key: 'loading', alignment: 'bottom-left' });

        const key = "downloademailtemplate";
        message.loading({
            content: 'Report generation in progress...',
            key,
            duration: 0,
            //style: {
            //    position: "fixed",
            //    bottom: 0,
            //    left: 0
            //},
        });
        
        var selectedShowCode = '';
        if (Array.isArray(selectedShow)) {
            selectedShowCode = selectedShow[0].toString();
        } else {
            selectedShowCode = selectedShow.toString();
        }

        let postdata = {
            reportName,
            reportOption,
            showcode: selectedShowCode,
        }

        downloadReportByName(postdata).then(response => {
            //console.log(response);
            //console.log(response.headers['content-disposition']);

            if (response.data) {
                //uses the fileName sent down from API enpoint in response header
                var fileName = response.headers['content-disposition'].replace('filename=', '');
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                //a.download = reportName + ".xlsx";
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);

                message.destroy(key);
            } else {
                toast.warning("Report is not yet defined");
            }
        });
    }

    function setSelectedShowCode(reportName, reportOption, selectedShow) {
        hideSelectShowModal();

        downloadReport(reportName, reportOption, selectedShow);
    }

    return (
        <Drawer
            closeIcon={<i className="fas fa-chevron-left float-left"></i>}
            title='Report/Output'
            placement={'right'}
            onClose={handleClose}
            visible={modalInfo.show}
            key='reportsSlider'
            width={'85%'}
            className="d-flex"
            footer={
                <div className="d-flex justify-content-between">
                    <div>
                        <button className="btn btn-outline-primary float-right" onClick={handleClose}>Close</button>
                    </div>
                </div>
            }
        >
            <div className="row align-items-start mt-3 ml-3 h-100">
                {Object.keys(reportsToDisplay).length > 0 ?
                    <Fragment>
                        {Object.keys(reportsToDisplay).map((key, idx) => {
                            return (
                                <div className="col col-lg-3 col-12" style={{ height: height.value + 'px' }} key={idx} ref={biggestGroup === key ? listRef : null} id="getHeight">
                                    <Card title={`${key} Reports`} className="d2p-reporting-card">
                                        {reportsToDisplay[key].map((r, idx2) => <p className="a u" key={idx2} value={r.reportId} id={r.reportName} onClick={() => handleReportRequest(r)}>{r.reportName}</p>)}

                                    </Card>
                                </div>
                            );
                        })}
                    </Fragment>
                    :
                    <div>
                        <h2 className="color-primary">Your Profile Is Not Configured For Access To Reporting/Output</h2>
                        <h4>Contact Your System Administrator To Request Access</h4>
                    </div>
                }

                {!height.triggered &&
                    <div className="fixed-white-cover" />
                }
            </div>

            {state.showSelectShowModal &&
                <SelectShowModal showModal={state.showSelectShowModal} hideSelectShowModal={hideSelectShowModal} setSelectedShowCode={setSelectedShowCode}
                    reportName={selectedReport.reportName} reportId={selectedReport.reportId} option1={selectedReport.option1} option2={selectedReport.option2} option3={selectedReport.option3} />
                //<SelectShowAndPriorShowsModal showModal={state.showSelectShowModal} hideSelectShowModal={hideSelectShowModal} setSelectedShowCode={setSelectedShowCode} modalTitle='Call Sheet Generator' />
            }
        </Drawer>
    );
}

export default ReportsSlider;